$white: #ffffff;
$violet-blue: #5d6bef;
$grey100: #f5f5f5;
$grey200: #f5f5f7;
$grey300: #dee5f2;
$grey400: #c2cade;
$grey500: #9fa7ba;
$grey600: #747b93;
$grey700: #646a8b;
$grey800: #464c62;
$grey900: #1b264b;

$accent: #5c6bf0;
$accent-light: #f6f8ff;
$on-general: #363b4c;
$on-general-sub: #6e748c;
$general-border: #d3d4da;
$danger: #ca4a46;
