@import "../theme/utils/breakpoints.scss";

.tecma-templatePage {
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  height: 100%;
  overflow: hidden;
  @media (max-width: $breakpoint-tablet-h) {
    flex-direction: column;
  }
  .body-container {
    background-color: #f5f5f7;
    padding: 4.5rem 7.75rem;
    width: 100%;
    height: 100%;
    overflow: auto;
    @media (max-width: $breakpoint-tablet-h) {
      padding: 3.5rem;
    }
    @media (max-width: $breakpoint-tablet-p) {
      padding: 1.5rem;
    }
  }
}
