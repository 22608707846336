@import '../theme/utils/colors.scss';

.loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  &.h-start {
    justify-content: flex-start;
  }
  &.h-end {
    justify-content: flex-end;
  }
  &.h-center {
    justify-content: center;
  }
  &.v-start {
    align-items: flex-start;
  }
  &.v-end {
    align-items: flex-end;
  }
  &.v-center {
    align-items: center;
  }
}

.loader-root {
  height: 40px;
  width: 40px;
  font-size: 6px;
  position: relative;
  .loader {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
    position: absolute;
    top: 43%;
    left: 42%;
  }
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em $white,
      1.8em -1.8em 0 0em $grey400,
      2.5em 0em 0 0em $grey400,
      1.75em 1.75em 0 0em $grey400,
      0em 2.5em 0 0em $grey400,
      -1.8em 1.8em 0 0em $grey400,
      -2.6em 0em 0 0em $grey200,
      -1.8em -1.8em 0 0em $grey100;
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em $grey100,
      1.8em -1.8em 0 0em $white,
      2.5em 0em 0 0em $grey400,
      1.75em 1.75em 0 0em $grey400,
      0em 2.5em 0 0em $grey400,
      -1.8em 1.8em 0 0em $grey400,
      -2.6em 0em 0 0em $grey400,
      -1.8em -1.8em 0 0em $grey200;
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em $grey200,
      1.8em -1.8em 0 0em $grey100,
      2.5em 0em 0 0em $white,
      1.75em 1.75em 0 0em $grey400,
      0em 2.5em 0 0em $grey400,
      -1.8em 1.8em 0 0em $grey400,
      -2.6em 0em 0 0em $grey400,
      -1.8em -1.8em 0 0em $grey400;
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em $grey400,
      1.8em -1.8em 0 0em $grey200,
      2.5em 0em 0 0em $grey100,
      1.75em 1.75em 0 0em $white,
      0em 2.5em 0 0em $grey400,
      -1.8em 1.8em 0 0em $grey400,
      -2.6em 0em 0 0em $grey400,
      -1.8em -1.8em 0 0em $grey400;
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em $grey400,
      1.8em -1.8em 0 0em $grey400,
      2.5em 0em 0 0em $grey200,
      1.75em 1.75em 0 0em $grey100,
      0em 2.5em 0 0em $white,
      -1.8em 1.8em 0 0em $grey400,
      -2.6em 0em 0 0em $grey400,
      -1.8em -1.8em 0 0em $grey400;
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em $grey400,
      1.8em -1.8em 0 0em $grey400,
      2.5em 0em 0 0em $grey400,
      1.75em 1.75em 0 0em $grey200,
      0em 2.5em 0 0em $grey100,
      -1.8em 1.8em 0 0em $white,
      -2.6em 0em 0 0em $grey400,
      -1.8em -1.8em 0 0em $grey400;
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em $grey400,
      1.8em -1.8em 0 0em $grey400,
      2.5em 0em 0 0em $grey400,
      1.75em 1.75em 0 0em $grey400,
      0em 2.5em 0 0em $grey200,
      -1.8em 1.8em 0 0em $grey100,
      -2.6em 0em 0 0em $white,
      -1.8em -1.8em 0 0em $grey400;
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em $grey400,
      1.8em -1.8em 0 0em $grey400,
      2.5em 0em 0 0em $grey400,
      1.75em 1.75em 0 0em $grey400,
      0em 2.5em 0 0em $grey400,
      -1.8em 1.8em 0 0em $grey200,
      -2.6em 0em 0 0em $grey100,
      -1.8em -1.8em 0 0em $white;
  }
}
