@use '../theme/utils/colors.scss' as *;

.navbar-item {
  align-items: center;
  width: 218px;
  height: 48px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  display: flex;
  text-decoration: none;
  transition: all 200ms ease;

  &:hover {
    background-color: #d3dae8 !important;
  }
  &:nth-child(1 of .bottom) {
    margin-top: auto;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    background-color: $white !important;
    box-shadow: -3px 0px 0px 0px $violet-blue;

    & .title {
      color: $violet-blue;
    }
    & path {
      stroke: $violet-blue;
    }
  }

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: flex-start;
  }
  .title {
    flex-grow: 1;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.25px;
    color: #464c62;
    text-decoration: none;
    font-family: 'lato';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: initial;
    padding-right: 16px;
  }

  .navbar-icon {
    &.tecma-icon {
      height: 1.125rem;
      width: 3rem;
      flex-shrink: 0;
      display: inline-flex;
      justify-content: center;
    }
  }

  &.subitem {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    padding: 0;
    margin-bottom: 0;
    &:hover {
      background-color: #d3dae8;
    }
    .title {
      margin-left: 0;
      padding-left: 22px;
    }
    .icon {
      height: 16px;
      width: 48px;
      svg {
        stroke: #464c62;
      }
    }
  }

  &.link {
    text-decoration: auto;
    background-color: unset;
  }
}

.MuiAccordion-root {
  box-shadow: none !important;
  background: none !important;
}

.MuiAccordionSummary-root.navbar-item {
  background: rgba(255, 255, 255, 0.2);
  margin: 0;
  padding: 0;
  transition:
    width 200ms ease,
    min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: #d3dae8;
  }
  .MuiAccordionSummary-content {
    align-items: center;
  }
}

.MuiAccordionSummary-content {
  transition: width 200ms ease;
  width: 204px;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

.navbar-item.item-expandable {
  .navbar-arrow {
    height: 16px;
    width: 16px;
    position: absolute;
    right: 16px;
  }
}

.navbar-item.item-user {
  background: rgba(255, 255, 255, 0.2);
  justify-content: flex-start;
  &:hover {
    background-color: #d3dae8;
  }
  .navbar-arrow {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      height: 16px;
    }
  }
  .title {
    text-transform: capitalize;
  }
}

.user-menu,
.user-language {
  .MuiPaper-root {
    box-shadow: none;
    filter: drop-shadow(0px 2px 18px rgba(100, 100, 100, 0.25));
  }
}

.user-menu {
  .MuiPaper-root {
    margin-left: 40px;
  }
  &-button.MuiButtonBase-root {
    display: block !important;
    width: 204px;
    height: 48px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #464c62;
    text-align: left;
    padding-left: 16px !important;

    &.underlined {
      border-bottom: 1px solid $grey300;
    }

    &.current-language {
      color: $violet-blue;
      position: relative;
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33331 8.6665L5.99998 11.3332L12.6666 4.6665' stroke='%235D6BEF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        position: absolute;
        right: 16px;
      }
      path {
        stroke: $violet-blue;
      }
    }

    &:hover {
      background-color: #f2f2f2;
    }

    &.language .icon {
      height: 16px;
      width: 16px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      path {
        stroke: $grey800;
      }
    }
  }

  a {
    text-decoration: none;
    color: #464c62;
  }
}

.user-language {
  .MuiPaper-root {
    margin-left: 8px;
  }
}

.MuiTooltip-tooltip.navbar-item-tooltip {
  background: rgba(37, 37, 37, 0.9);
  border-radius: 4px;
  padding: 8px;
  color: $white;
  font-size: 13px;
  letter-spacing: 0.4px;

  &.tooltip-user {
    text-transform: capitalize;
  }
}
