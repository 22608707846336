* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
}

body,
button {
  font-family: "lato", sans-serif;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: unset;
}
