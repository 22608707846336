@import "../theme/utils/colors.scss";
@import "../theme/utils/breakpoints.scss";

.navbar-container {
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 40px 24px 24px;
  height: 100%;
  background: #e0e5ef;
  border-right: 1px solid $grey400;
  z-index: 1;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;

  &.collapsed {
    .navbar-item {
      width: 48px;
      background-color: transparent;

      .title {
        display: none;
      }
    }
    .navbar-menu {
      justify-content: flex-end;
    }
    .navbar-menu-title {
      display: none;
    }
    .navbar-collapse-button {
      transform: scaleX(-100%);
    }

    .navbar-arrow {
      display: none;
    }
    .MuiAccordionSummary-content {
      width: 48px;
    }
  }

  @media (max-width: $breakpoint-tablet-h) {
    padding: 16px 24px 24px;

    .navbar-logo {
      display: none;
    }
  }
}

.header-mobile {
  display: none;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #e0e5ef;
  height: 80px;
  flex-shrink: 0;

  .header-mobile-menu-button {
    height: 48px;
    width: 48px;
    background: #e6eaf2;
    border-radius: 2px;
  }
  .header-mobile-logo {
    height: 48px;
    width: 48px;
  }
  @media (max-width: $breakpoint-tablet-h) {
    display: flex;
    border-bottom: 1px solid $grey400;
  }
}

// .navbar-mobile-placeholder {
//   width: 96px;
//   flex-shrink: 0;
//   display: none;
//   &.show {
//     display: initial;
//   }
// }

.navbar-mobile-black-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 5;
  background: rgba(0, 0, 0, 0.4);
  &.show {
    display: initial;
  }
}

.navbar-logo {
  margin: 0 auto;
  margin-bottom: 65px;
}

.navbar-menu {
  font-family: "lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $grey600;
  margin-bottom: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .navbar-collapse-button {
    height: 48px;
    width: 48px;
    &:hover {
      background-color: #d3dae8;
    }
    &-icon {
      height: 24px;
      width: 48px;
    }
  }
}

.navbar-routes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.navbar-footer {
  & > .navbar-item:first-child {
    margin-top: 16px;
  }

  padding-bottom: 24px;
  & > div {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-powered {
    position: sticky;
    bottom: 0;
    margin-top: 32px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: $grey700;
    &-text {
      margin-bottom: 8px;
    }
  }
}

.user-mobile-menu {
  margin-top: 32px;

  .MuiPaper-root {
    box-shadow: none;
    filter: drop-shadow(0px 2px 18px rgba(100, 100, 100, 0.25));
  }

  .mobile-menu-button-withIcon > div {
    display: flex;
    align-items: center;
  }

  .mobile-menu-button-icon {
    position: absolute;
    height: 16px;
    width: 16px;
    path {
      stroke: $grey800;
    }
  }

  .isLeftSide {
    left: 20px;
  }

  .isRightSide {
    right: 20px;
  }

  .user-language-mobile-menu .user-menu-button {
    padding-left: 40px !important;
  }
}
